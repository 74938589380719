.background{
    background: linear-gradient(150deg, rgba(18,49,131,255) 35%, rgba(143,169,218,255) 65%, rgba(229,229,229,255) 100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width:100%;
    height: auto;
    left: 0px;
    display: flex;
    flex-wrap: wrap;
    flex: 1 2 0;
    padding-bottom: 40px;
}

.background > .right-area{
    min-width: 380px;
    right: 0;
    padding-top: 20px;
    flex: 1 2 0;
    display: flex;
    justify-content: center;
}

.background > .lastcourse-courses-page{
    min-width: 350px;
    max-width: 877px;
    height: 100%;
    top: 32px;
    overflow: hidden;
    flex: 1 2 0;
    display: flex;
    flex-direction: column;
    padding-left: 46px;
    padding-top: 32px;
    justify-content: center;
}

.lastcourse-courses-page > .videoThumbnail-courses-page{
    width: calc(100% - 46px);
    height: auto;
    max-width: 831px;
    min-width: 300px;
    left: 46px;
    top: 94px;
    overflow: hidden;
    border-radius: 15px;

}
.videoThumbnail-courses-page > div{
    display: flex;   
}

.videoThumbnail-courses-page > .mediaArea-courses-page{
    width: 100%;
    height: 100%;
    position: relative;    
}
.vertical-grid-container-courses-page{
    gap: 11px;
    flex: 1 2 0;
    display: flex;
    flex-direction: column;
    max-width: 600px;
    height: 100%;
    padding-left: 40px;
    padding-right: 40px;
    justify-content: center;
    align-items: center;    
}
.vertical-grid-container-courses-page > .title-case{
    width: auto;
    font-family: 'Manrope';
    font-weight: 700;
    font-style: bold;
    font-size: 32px;
    color: #EBF2FC;    
}
.vertical-grid-container-courses-page > .subtitle-case{
    width: auto;
    height: auto;
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    font-size: 28px;    
}
.vertical-grid-container-courses-page > .button-case{
    width: 100%;
    height: 76px;
    background-color: #197DFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
}
.button-case > .text-button-container{
    display: flex;
    gap: 10px;
    align-items: center;
}
.text-button-container > div {
    font-family: 'Manrope';
    font-weight: 700;
    font-style: bold;
    font-size: 24px;
    color: #EBF2FC;  
}
.text-button-container > img{
    width: 18.79px;
    height: 23px;
}

.vertical-grid-container-courses-page > .text-input-container{
    width: 100%;
    height: 30px;
    display: flex;
}
.text-input-container > div{
    font-family: 'Roboto';
    font-weight: 700;
    font-style: bold;
    size: 20px;
    padding-top: 6px;
    padding-right: 15pxpx;
}


.calendar-container > div{
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
}
.calendar{
    width: auto;
    height: 80px;
    border-radius: 18px;
    background-color: #003C89;
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 5px;

}

.item-calendar{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.number{
    font-family: 'Manrope';
    font-weight: 700;
    font-style: bold;
    font-size: 30px;
    color: #EBF2FC;
    display: flex;
}
.text{
    font-family: 'Manrope';
    font-weight: 300;
    font-style: normal;
    font-size: 10px;
    color: #EBF2FC;
}

.point-calendar{
    font-family: 'Manrope';
    font-weight: 700;
    font-style: bold;
    font-size: 30px;
    color: #EBF2FC;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    position: relative;
    top: 11px;
}
.return{
    display: flex;
    gap: 14px;
    margin-bottom: 10px;
    cursor: pointer;
}
.return > img{
    width: 16px;
    height: 16px;
}
.return > div{
    font-family: 'Roboto';
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    color: #8F5D00;
}
