.login {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 600px;
    overflow: visible;
}
.login a {
    width: 100%;
    font-size: 18px;
    color: #4866f7;
    font-weight: 700;
}
.login h1{
    margin: 20px;
}
.login .signup-button{
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: 600px;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 600;
}
.login .signup-button img {
    margin: 0px 10px;
}
.login .signup-email {
    display: flex;
    background-color: #4866f7;
    color: white;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 10px;
    width: 100%;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 600;
}
.login .signup-here{
    margin: 20px;
    width: 100%;
}
.login .btn:hover{
    cursor: pointer;
    /*background-blend-mode: darken;*/
    filter: brightness(80%);
}
.login-data {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.login-data input {
    outline: none;
    background-color: #1c1328;
    color: white;
    border: none;
    padding: 15px;
    margin: 10px;
    border-radius: 30px;
    font-size: 16px;
}
.login-data input[type=text]::placeholder{
    color: white;
}
.login-data a{
    text-align: end;
    padding: 10px;
}
/*Line*/
.login .login-title{
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lineText {
    overflow: visible;
    text-align: center;
}

.lineText:before,
.lineText:after {
    background-color: rgb(75, 75, 75);
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
}
  
.lineText:before {
    right: 0.5em;
    margin-left: -50%;
}
  
.lineText:after {
    left: 0.5em;
    margin-right: -50%;
}