@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&family=Roboto:wght@300;400;700&display=swap');

.App {
  /* overflow: auto; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: 100vw;
  height: 100vh;
  position: absolute;
}

.form-body {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.form-container {
  width: 369px;
  height: auto;
  background: #FFFFFF;
  border-radius: 10px;
  justify-content: center;
  justify-items: center;
}

.form {
  display: flex;
  flex: auto;
  flex-direction: column;
  margin: 32px 32px 32px 32px;
  font-size: calc(10px + 2vmin);
  font-family: Manrope;
  color: #000000;
  line-height: 20px;
}

.form > * > button {
  background: #197DFF;
  border-radius: 5px;
  border-style: none;
  width: 100%;
  height: 50px;
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  cursor: pointer;
}

.form > * > button:hover {
  background: #006eff;
}

.form > * > button:active {
  background: #0063e6;
}

label {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  color: #000000;
  width: 100%;
}

.form > .footer {
  text-align: center;
}

.form > .footer > a {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  text-align: center;
  color: #8F5D00;
}

.form > .footer > label {
  font-size: 18px;
}

.form > .header {
  text-align: center;
}

.form > .header > h1 {
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
}

.form > .header > img {
  width: 60%;
}

.blue-button-a{
  background: #197DFF;
  border: 0;
  border-radius: 5px;
  color: white;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blue-button-a:hover {
  background: #0d76ff;
}

.blue-button-a:active {
  background: #006cfa;
}

a{
  cursor: pointer;
  text-decoration: underline;
}

[contentEditable=true]:empty:before {
  content: attr(placeholder);
  opacity: 0.6;
  cursor: text;
}

.tour{
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;

}
.tour-title{
  color: #DB8F00;
  font-family: 'Manrope';
  font-size: 14px;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.gap15 {
  gap: 15px;
}

[type="file"] {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}