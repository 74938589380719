.signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 100%;
    max-width: calc(100% - 700px);
  }
  .signup h1 {
    margin: 10px;
  }
  .signup p {
    font-size: 16px;
    margin: 10px;
  }
  .signup > p > a {
    font-weight: 700;
    color: #4866f7;
  }
  .signup .data {
    display: flex;
    flex-direction: column;
    margin: 10px;
    width: 100%;
  }
  .signup .data div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .telephone{
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: black;
    border-radius: 30px;
    margin: 10px;
  }
  .profile-field,
  .signup input[type="text"],
  .signup input[type="password"] {
    background-color: rgb(1, 26, 93);
    color: white;
    border: none;
    padding: 15px;
    margin: 10px;
    border-radius: 30px;
    font-size: 16px;
  }
  .signup input[type="text"]::placeholder {
    color: white;
  }
  .signup input[type="submit"] {
    background-color: #4866f7;
    border: none;
    padding: 10px;
    margin: 10px 0px;
    border-radius: 20px;
    color: white;
    font-size: large;
    font-weight: 500;
  }
  