body{
    overflow-y: auto;
}
.pricing{
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.pricing .price .invisible {
    position: absolute;
    top: 0%;
    transform: translate(0,50%);
    font-size: medium ;
    font-weight: lighter;
}
.pricing .create-price,
.pricing .price {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    margin: 30px;
    height: 400px;
    width: 300px;
    background-color: white;
    border-radius: 15px;
    position: relative;
}
.pricing .create-price .create,
.pricing .price .get {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    width: 300px;
    height: 400px;
}
.multi-select{
    display: flex;
    width: 100%;
}
.multi-select > * {
    width: 100%;
}
.pricing .create-price .create > *,
.pricing .price .get > * {
    margin: 5px;
}
.pricing .price .get span:nth-child(1){
    font-size: xx-large;
    font-weight: 600;
}
.pricing .price .get span:nth-child(2){
    font-size: x-large;
    font-weight: 700;
}
.pricing .price .get span:nth-child(3){
    font-size:large;
    font-weight: 600;
}
.pricing .price .get span:nth-child(4){
    font-size:large;
}

.pricing .create-price > input[type='button'],
.pricing .price > input[type='button']{
    border: none;
    color: white;
    font-size: x-large;
    font-weight: 600;
    background-color: #011a5d;
    padding: 10px 15px;
    cursor: pointer;
}
.pricing .price img {
    position: absolute;
    width: 42px;
    height: 42px;
    right: 0%;
    transform: translate(25%,-25%);
    padding: 5px;
    border-radius: 25px;
    cursor: pointer;
    z-index: 1;
}
.pricing .price .edit {
    background-color: #011a5d;
    top: 15%;
}
.pricing .price .delete {
    background-color: rgb(201, 0, 0);
    top: 0%;
}

/*Crear nuevo pricing*/
.pricing .create-price .create > span{
    font-size: x-large;
    font-weight: 600;
}
.pricing .create-price .create > input[type='text']{
    padding: 5px;
    width: 100%;
}
.price .modify {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.price .modify > span {
    font-size: x-large;
    font-weight: 600;
    text-align: center;
}
.price .modify > * {
    width: 100%;
    margin: 5px;
    padding: 5px;
}

/*Purchase*/
.price .purchase{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}