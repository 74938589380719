
.floating{
    display: flex;
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 10;
    transition: 0.9s ease all;
}
.floating img {
    width: 80px;
    height: 80px;
    position: absolute;
}
.first-page {
    background-color: #0e0b10;
    width: 100%;
    margin-top: 120px;
    height: 860px;
    position: relative;
    margin-top: 0;
}

.first-page>div {
    position: absolute;
    color: white;
    font-size: 100px;
    font-weight: 700;
    bottom: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.first-page>div>p {
    color: white;
    font-size: 90px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    text-align: center;

}

.button-green {
    background-color: #b724fb;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 350px;
    height: 50px;
}

.button-green>p {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}

.button-green>img {
    margin: 0;
}

.button-green:hover {
    background-color: #0c0c0c;
}

#curso1 {
    background: url(../../assets/images/botoncuadro_01.png) no-repeat;
    background-size: contain;
}

#curso2 {
    background: url(../../assets/images/botoncuadro_02.png) no-repeat;
    background-size: contain;
}

#curso3 {
    background: url(../../assets/images/botoncuadro3.png) no-repeat;
    background-size: contain;
}

#curso4 {
    background: url(../../assets/images/botoncuadro_04.png) no-repeat;
    background-size: contain;
}


.purple-card {
    width: 400px;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}

.purple-card>div {
    position: absolute;
    width: 100%;
    max-width: 280px;
    bottom: 80px;
    text-align: center;
    font-size: 25px;
}

.gray-card {
    background-color: #383838;
    padding: 25px 70px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.gray-card>p {
    margin: 0;
}

.second-page {
    background-color: #262626;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    gap: 25px;
}

.second-page>div {
    display: flex;
    gap: 25px;
    flex-wrap: wrap;
}

.second-page>p {
    font-weight: 300;
    display: flex;
    text-align: center;
    font-size: 25px;
}

.third-page {
    background-color: #f0f0f0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.third-page>img {

    width: 100%;
    max-width: 1900px;
}

.third-page>div>p {
    font-size: 55px;
    padding: 35px;
    text-align: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: center;
    gap: 55px;
}

.row>div {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.paragraph:hover {
    background-color: #7b62df31;
}

.row>div>h3 {
    text-align: center;
    font-size: 18px;
}

.row>div>p {
    text-align: justify;
    font-size: 18px;
}

.pink-button {
    background-color: #ef7d8a;
    max-width: 150px;
    display: flex;
    justify-content: center;
    font-weight: 400;
    padding: 15px 55px;
    color: white;
}

.pink-button:hover {
    background-color: #7b62df;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.four-page {
    background-image: linear-gradient(to right, #4325bd, #cf8cad);
}

.four-page>h1 {
    color: #a9e5c4;
    padding: 15px;
    font-size: 35px;
    font-weight: 400;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
}

.card-img {
    max-width: 800px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 25px;
    color: white;
}

.green-btn {
    border-style: solid;
    border-radius: 25px;
    padding: 10px 25px;
    max-width: 150px;
    display: flex;
    justify-content: center;
    color: #a9e5c4;
    font-weight: 500;
}

.green-btn:hover {
    background-color: rgba(255, 255, 255, 0.377);
}

.card-img>img {
    border-radius: 55px;
}

.card-gray {
    -webkit-filter: grayscale(100%) !important;
    filter: grayscale(100%) !important;
}

.card-gray:hover {
    -webkit-filter: grayscale(0%) !important;
    filter: grayscale(0%) !important;
}

.six-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 150px;
    padding: 25px 0;
    position: relative;
    height: 250px;
    z-index: 1;
}

.logo-anim {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0.5;
}

.seven-page {
    background-color: #0e0b10;
    background: url(../../assets/images/draabajo.png) no-repeat;
    background-size: contain;
    width: 100%;
    margin-top: 120px;
    height: 1860px;
    position: relative;
    margin-top: 0;
}

.card-container-info {
    width: 100%;
    position: absolute;
    top: 350px;
    color: white;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
}

.card-container-info-gray {
    width: 100%;
    position: absolute;
    bottom: 450px;
    color: white;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    flex-direction: column;
}

.card-info-gray {
    background-color: #414141;
    padding: 15px;
    border-radius: 15px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 200px;
    gap: 15px;
}

.card-info-gray>h1 {
    font-size: 25px;
    font-weight: 400;
    color: #a0a0a0;
    margin: 0;
}
.card-info-gray>p {
    font-size: 35px;
    font-weight: 400;
    color: white;
    margin: 0;
}

.card-container-info-gray>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 900px;
}

.card-container-info>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 900px;
}

.card-container-info>div>h1 {
    font-size: 55px;

}

#home-input {
    background-color: #0c0c0c00;
    color: white;
    border-bottom: 2px solid #3b3b72;
}

#home-input::placeholder {
    /* Edge 12-18 */
    color: white;
}

.card-info {
    border-style: solid;
    border-color: #4646a1;
    padding: 15px;
    border-radius: 15px;
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
}

.card-info>h1 {
    font-size: 35px;
    font-weight: 400;
}
@media (max-width:1600px) {
    .seven-page {
        height: 1800px;
        background: #2b2b2b;
        background: url(../../assets/images/draabajo.png) no-repeat;
    }
    .card-container-info-gray {
        bottom: 270px;
        height: auto;
    }
}

@media (max-width:900px) {
    .first-page>div>p {
        font-size: 55px;
    }

    .first-page>div {
        flex-wrap: wrap;
    }

    .first-page>div>div {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .second-page {
        height: auto;
    }

    .row {
        flex-wrap: wrap;
    }

    .third-page {
        height: auto;
    }

    .five-page {
        height: auto;
        flex-wrap: wrap;
    }

    .six-page {
        font-size: 70px;
    }

    .first-page>div>p {
        font-size: 35px !important;
    }

    .card-img>img {
        width: 100%;
    }

    .column>img {
        width: 100%;
    }

    .card-info {
        width: 100%;
        height: auto;
    }

    .card-container-info {
        top: 0;
    }

    .card-container-info>div>h1 {
        font-size: 15px;
    }

    .card-info>h1 {
        font-size: 15px;
    }

    .card-info {
        height: auto;
    }

    .seven-page {
        height: 800px;
        background: #2b2b2b;
    }
    .card-container-info-gray {
        bottom: 50px;
        height: auto;
    }
}