.expandable-box {
    background-color: #123183;
    padding: 5px;
    margin: 10px;
    border-radius: 10px;
    transition: height 0.3s ease;
    height: auto;
}
.expandable-box .box-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.expandable-box .box-header .left-content{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.expandable-box .box-header .left-content span{
    font-size: 32px;
    font-weight: 800;
    color: white;
    background-color: #12c5ad;
    width: 50px;
    height: 50px;
    text-align: center;
    border-radius: 30px;
    margin: 5px;
}
.expandable-box .box-header .left-content p,
.expandable-box .box-header .left-content input[type= 'text']{
    font-weight: 700;
    font-size: 28px;
    color: white;
    margin: 10px;
    background-color: transparent;
}
.expandable-box .box-header .right-content{
    display: flex;
    align-items: center;
}
.expandable-box .box-header .right-content > *{
    cursor: pointer;
}
.expandable-box .box-header .right-content img{
    width: 32px;
    padding: 5px;
}
.expandable-box .box-header .right-content input[type='checkbox']{
    height: 32px;
    width: 32px;
    padding: 5px;
}
.expandable-box > div {
    overflow: visible;
    padding: 10px;
}