.save-data {
    position: absolute;
    bottom: 50px;
    right: 50px;
    z-index: 1;
}
.save-data input{
    background-color: Green;
    color: white;
    border: none;
    padding: 15px;
    font-size: xx-large;
    font-weight: 700;
    cursor: pointer;
    border-radius: 15px;
}
.course-template .header {
    padding: 30px;
    background-color: #123183;
    background-image: linear-gradient(150deg, #123183 35%, #8fa9da 65%, #e5e5e5);
    display: flex;
    flex-direction: row;
    position: relative;
}
.course-template .header div img {
    width: 80%;
    border-radius: 30px;
}
.course-template .img-container{
    position: relative;
    display: inline-block;
}
.course-template .image-container .text-input {
    position: absolute;
    top: 50%; /* Ajusta la posición vertical según sea necesario */
    left: 50%; /* Ajusta la posición horizontal según sea necesario */
    transform: translate(-50%, -50%); /* Centra el input text */
    background: rgba(255, 255, 255, 0.7); /* Fondo semi-transparente para mejor visibilidad */
    border: none;
    padding: 5px;
    font-size: 16px;
  }
.course-template .header .data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.course-template .header .data .title {
    font-size: 36px;
    font-weight: 700;
    color: white;
    background-color: transparent;
}
.course-template .header .data .subtitle {
    font-size: 30px;
    color: black;
}
.course-template .content{
    padding: 20px;
}
.course-template .content .case-content {
    background-color: #d2d5e6;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;
}
.course-template .content .case-content div {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.course-template .content .case-content div img{
    cursor: pointer;
    margin-right: 10px;
}
.course-template .content .case-content div span,
.course-template .content .case-content div input{
    font-size: 24px;
    font-weight: 300;
    background-color: transparent;
}
.course-template .content .case-content > img {
    width: 32px;
    cursor: pointer;
}

.course-template .content .add-case{
    background-color: white;
    padding: 5px;
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    font-size: larger;
    font-weight: 700;
    box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.25) inset;
}
.course-template .content .add-case * {
    margin: 0px 10px;
}