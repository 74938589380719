.edit-users {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;;
}
/*SearchBar*/
.edit-users .title {
    margin: 20px auto;
}
.edit-users .searcher {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.edit-users input[type="text"] {
    background-color: black;
    border-radius: 10px;
    color: white;
    padding: 10px;
}
 
.access-control {
    background-color: white;
    padding: 15px;
    border-radius: 15px;
    margin: 15px;
}
.edit-users .current-edit {
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 15px;
    border-radius: 15px;
    margin: 15px;
}
.edit-users .subscriptions {
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.edit-users .content{
    background-color: white;
    padding: 15px;
    margin: 15px;
    border-radius: 15px;
    
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    height: 350px;
    width: 500px;
    position: relative;
}
.edit-users .content > span {
    display: block;
}
.edit-users .content select{
    background-color: black;
    color: white;
    padding: 5px;
    margin: 0px 5px;
    border: none;
}
.edit-users .content input{
    border: solid 1px black;
    padding: 5px;
    margin: 0px 5px;
}

.visible {
    color: #18cd00;
    content: "ACTIVE";
}
.invisible {
    color: rgb(197, 0, 0);
    content: "EXPIRED";
}

.edit-users .referenced span{
    text-align: center;
    margin: 10px;
    font-size: large;
    font-weight: 600;
}
.edit-users .referenced{
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 30px;
}
.edit-users .referenced span{
    text-align: center;
}
.edit-users .referenced table {
    
    border-collapse: 1px red;
}
.edit-users .referenced table thead th{
    background-color: black;
    color: white;
    text-align: start;
}
.edit-users .referenced table th{
    padding: 10px;
}
.edit-users .referenced table tr:nth-child(even) {
    background-color: #f2f2f2;
}
.edit-users input[type=button] {
    font-size: large;
    font-weight: 700;
    border: none;
    padding: 15px;
    margin: 10px;
    cursor: pointer;
    border-radius: 15px;
}
.edit-users .blue{
    background-color: #00046d;
    color: white;

}