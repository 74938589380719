.welcomeArea {
    width:100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1 2 ;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(221, 231, 231);
    font-weight: 700;
    font-size: 35px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgb(235, 238, 237) 0%, rgb(72, 219, 187) 35%, rgb(21, 76, 158) 100%);
    height: 100%;
}
.welcomeArea > .lastcourse{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}
.videoThumbnail{
    display: flex;
    justify-content: center;
    align-items: center;
 }
 
 .lastcourse > .videoThumbnail{
     position: relative;
 }
 .text-card{
    position: absolute;
    width: 100%;
    max-width: calc(100% - 10px);
    display: flex;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.637) 0%, rgba(0, 0, 0, 0.637) 35%, rgba(0, 0, 0, 0.534) 100%);
    border-radius: 25px;
    bottom: 9px;
  }
  .img-card {
    position: absolute;
    width: 40px;
    right: 10px;
    top: 10px;
    z-index: 1;
    cursor: pointer;
  }
  .text-card  div {
    display: flex;
    font-size: 25px;
    color: white;
  }
  .add-course {
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
  }