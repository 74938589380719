.footer{
    background-color: rgb(255, 136, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 5px;
}
.privacy{
    display: flex;
    justify-content: center;
    align-items: center;
}
a{
    text-decoration: none;
}