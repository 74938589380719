.header {
    display: flex;
    height: auto;
    top: 0px;
    left: 0px;
    right: 5px;
    background: #011a5d;
    align-items: center;
}

.header .area {
    height: calc(100vh - 61px);
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: #f0f2f5;
}

.header > .logo {
    cursor: pointer;
    height: 50%;
}

.header .accountIcon {
    width: 38.33px;
    height: 38.33px;
    top: 11px;
    right: 10px;
}

.header .menu-text {
    margin: 0px 20px;
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: right;
    align-items: center;
    flex-wrap: wrap;
}
.header .menu-text > * {
    color: white;
    font-weight: 700;
    text-decoration: none;
}

.header .account{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
}
.header .account img{
    border-radius: 30px;
    width: 50px;
    margin: 5px;
}
.header .account span {
    color: white;
    font-size: medium;
    margin: 5px;
}

@media (max-width: 608px) {
    .header .menu-text{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.header .dropdown {
    position: relative;
    display: inline-block;
}
.header .dropdown .drop-btn{
    background-color: transparent;
    color: white;
    padding: 16px;
    font-size: 16px;
    border: none;
    cursor: pointer;
}
.header .dropdown .dropdown-content{
    display: none;
    position: absolute;
    background-color: #f9f9f962;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.header .dropdown .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.header .dropdown .dropdown-content a:hover{
    background-color: #f1f1f1;
}